

























import { documentsNamespace } from '@/store/documents'
import { DocumentsActionTypes } from '@/store/documents/Types'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Nav extends Vue {
  @documentsNamespace.State('documentCountNew')
  private documentCountNew!: number

  @documentsNamespace.State('documentExecCountNew')
  private documentExecCountNew!: number

  @documentsNamespace.Action(DocumentsActionTypes.A_FETCH_DOCUMENTS_COUNT_NEW)
  private fetchDocumentCountNew!: () => Promise<number>

  @documentsNamespace.Action(DocumentsActionTypes.A_FETCH_DOCUMENTS_EXEC_COUNT_NEW)
  private fetchDocumentExecCountNew!: () => Promise<number>

  created() {
    this.fetchDocumentCountNew()
    this.fetchDocumentExecCountNew()
  }
}
